export default {
  card_declined: "Tu tarjeta ha sido rechazada.",
  card_declined_card_not_supported: "No se admite tu tarjeta.",
  card_number_in_name_field: "Asegúrate de que el campo del nombre no contenga un número de tarjeta.",
  card_number_in_postal_code_field: "Asegúrate de que el campo del código postal no contenga un número de tarjeta.",
  customer_canceled_authentication: "Para completar esta transacción debes autenticarte.",
  email_invalid: "Dirección de correo electrónico no válida.",
  expired_card: "La tarjeta ha caducado.",
  incomplete_au_bank_account_bsb: "El BSB que has introducido está incompleto.",
  incomplete_au_bank_account_number: "El número de cuenta que has introducido está incompleto.",
  incomplete_card: "Introduce los datos de tu tarjeta.",
  incomplete_cvc: "El código de seguridad de tu tarjeta está incompleto.",
  incomplete_expiry: "La fecha de caducidad de tu tarjeta está incompleta.",
  incomplete_iban: "El IBAN que has introducido está incompleto.",
  incomplete_number: "El número de tu tarjeta está incompleto.",
  incomplete_zip: "Tu código postal está incompleto.",
  // incorrect_cvc: "El código de seguridad de tu tarjeta es incorrecto.",
  incorrect_cvc: "Error de procesamiento.",
  incorrect_number: "El número de tu tarjeta es incorrecto.",
  incorrect_zip: "El número de tu tarjeta no se corresponde con el código postal.",
  invalid_au_bank_account_bsb: "El BSB que has introducido no es válido.",
  invalid_au_bank_account_number_testmode: "El número de cuenta que has introducido no es válido en modo de prueba.",
  invalid_cvc: "El código de seguridad de tu tarjeta no es válido.",
  invalid_expiry_month: "La fecha de caducidad de tu tarjeta no es válida.",
  invalid_expiry_month_past: "La fecha de caducidad de tu tarjeta ya ha pasado.",
  invalid_expiry_year: "El año de caducidad de la tarjeta no es válido.",
  invalid_expiry_year_past: "El año de caducidad de la tarjeta ya ha pasado.",
  invalid_iban: "El IBAN que has introducido no es válido.",
  invalid_iban_country_code: "El IBAN que has introducido no es válido: no es un código de país admitido.",
  invalid_iban_start: "El IBAN debe empezar con un código de país de dos letras.",
  invalid_number: "El número de tu tarjeta no es válido.",
  payment_intent_authentication_failure: "No podemos autenticar tu método de pago. Elige otro método y vuelve a intentarlo.",
  payment_intent_unexpected_state: "Se ha producido un error de procesamiento.",
  process_error_intransient: "Ha ocurrido un error durante el procesamiento de tu tarjeta.",
  processing_error: "Ha ocurrido un error durante el procesamiento de la tarjeta. Vuelve a intentarlo en unos minutos.",
  setup_intent_authentication_failure: "No podemos autenticar tu método de pago. Elige otro método y vuelve a intentarlo.",
  setup_intent_unexpected_state: "Se ha producido un error de procesamiento.",
  unexpected: "Ha ocurrido un error inesperado.",
  incomplete_payment_details: "Proporciona los datos completos del pago.",
  payment_method_invalid_parameter: "Los datos de pago que has proporcionado no son válidos.",
  payment_method_microdeposit_verification_amounts_mismatch: "Los importes especificados no coinciden con los enviados a la cuenta bancaria.",
  payment_method_microdeposit_verification_attempts_exceeded: "Has superado la cantidad de intentos de verificación permitidos.",
  processing_error_intransient: "Algo salió mal. Vuelve a intentarlo más tarde.",
  payment_method_microdeposit_verification_amounts_invalid: "Debes proporcionar dos importes de microdepósitos diferentes.",
  incomplete_email: "Tu dirección de correo electrónico está incompleta.",
  incomplete_card_name: "Proporciona el nombre que aparece en tu tarjeta.",
  incomplete_name: "Proporciona tu nombre completo.",
  invalid_au_bank_account_bsb_livemode: "El número de BSB no es válido en modo activo.",
  empty_phone_number: "Proporciona un número de teléfono móvil.",
  incomplete_phone_number: "El número de teléfono móvil está incompleto.",
  invalid_phone_number: "El número de teléfono móvil no es válido.",
  incomplete_address: "Este campo está incompleto.",
  incomplete_boleto_tax_id: "El CPF/CNPJ está incompleto.",
  incomplete_boleto_tax_id_cnpj_only: "El CNPJ está incompleto.",
  instant_verification: "Ha ocurrido un error inesperado al intentar utilizar la verificación instantánea.",
  invalid_au_bank_account_bsb_testmode: "El número de BSB no es válido en el modo de prueba. Usa 000-000.",
  invalid_postal_code: "El código postal no es válido.",
  invalid_zip: "El C. P. no es válido.",
  invalid_phone_number_prefix: "Introduce un código de país válido que empiece por \"+\".",
  incomplete_nz_bank_account_account_number: "Incompleto.",
  incomplete_nz_bank_account_bank_code: "Incompleto.",
  incomplete_nz_bank_account_branch_code: "Incompleto.",
  incomplete_nz_bank_account_suffix: "Incompleto.",
  invalid_nz_bank_account_number: "No válido",
  invalid_nz_bank_account_number_bank_code: "No válido",
  invalid_nz_bank_account_number_branch_code: "No válido",
  nz_bank_account_invalid_account_number_length: "Tu número de cuenta bancaria está incompleto.",
  incomplete_mandate_checkbox: "Marca esta casilla para aceptar.",
  incomplete_nz_bank_name: "Proporciona el nombre del banco en el que está tu cuenta.",
  instant_verification_incomplete: "No hay ninguna cuenta corriente ni de ahorros vinculada. Vincula una para completar esta transacción.",
  po_box_prohibited: "Está prohibido el apartado de correos.",
  incomplete_id_bank_transfer_bank: "Selecciona tu banco.",
  card_brand_blocked: "No se acepta esta marca de tarjeta.",
  civ_unexpected: "Se ha producido un error. Elige un método de pago distinto y vuelve a intentarlo.",
  invalid_bank_account_account_number: "El número de cuenta no es válido.",
  invalid_bank_account_routing_number: "El código Sort no es válido.",
  modify_bacs_debit_bank_details: "Corrige los datos de tu cuenta bancaria",
  missing: "There is no card on a customer that is being charged.",
  rate_limit: "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
  authentication_required: "La tarjeta fue rechazada debido a que la transacción requiere autenticación.",
  approve_with_id: "El pago no puede ser autorizado.",
  do_not_honor: "El banco del cliente ha rechazado la transacción. Si el problema persiste, favor de comunicarse con su banco.",
  do_not_try_again: "El banco del cliente ha rechazado la transacción.",
  duplicate_transaction: "Recientemente se envió una transacción con la misma cantidad y la información de la tarjeta de crédito.",
  fraudulent: "La tarjeta ha sido rechazada por una razón desconocida.",
  issuer_not_available: "No se pudo contactar con el banco del cliente.",
  lost_card: "El pago ha sido rechazado por que la tarjeta se ha reportado como perdida.",
  merchant_blacklist: "La tarjeta fue rechazada. Favor de intentar con otra tarjeta.",
  new_account_information_available: "El cliente debe comunicarse con su banco para verificar que la tarjeta funciona correctamente.",
  no_action_taken: "La tarjeta ha sido rechazada. El cliente debe contactarse con su banco para más información.",
  not_permitted: "La tarjeta ha sido rechazada. El cliente debe contactarse con su banco para más información.",
  pickup_card: "La tarjeta no se puede utilizar para realizar este pago (es posible que se haya reportado como extraviada o robada).",
  reenter_transaction: "Ha ocurrido un problema al procesar la tarjeta, intente de nuevo más tarde. Si el problema persiste favor de comunicarse con su banco.",
  restricted_card: "La tarjeta no se puede utilizar para realizar este pago.",
  revocation_of_all_authorizations: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  revocation_of_authorization: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  security_violation: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  service_not_allowed: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  stolen_card: "The payment has been declined because the card is reported stolen.",
  stop_payment_order: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  testmode_decline: "Ha usado una tarjeta de prueba.",
  try_again_later: "Intente nuevamete más tarde. Si el problema persiste, favor de comunicarse con su banco para más información.",
  withdrawal_count_limit_exceeded: "Le límite del saldo o crédito disponible en la tarjeta ha sido excedido.",
  cortesy_error: "La cortesía no es válida, fue usada recientemente.",
  call_issuer: "Se ha rechazado tu tarjeta. Ponte en contacto con tu banco para conocer más detalles.",
  card_not_supported: "Tu tarjeta no se admite.",
  card_velocity_exceeded: "Tu tarjeta ha sido denegada por intentos repetidos de compra con demasiada frecuencia.",
  currency_not_supported: "No se acepta tu tarjeta para esta divisa.",
  fake_merchant_test_card: "Se ha rechazado la tarjeta para activar el proceso de verificación. Esta tarjeta debe utilizarse para una cuenta Custom creada en el modo de prueba.",
  generic_decline: "Tu tarjeta ha sido rechazada.",
  incorrect_pin: "PIN incorrecto.",
  insufficient_funds: "Tu tarjeta no tiene fondos suficientes.",
  invalid_account: "Cuenta no válida.",
  invalid_amount: "Importe no válido.",
  live_mode_test_card: "Se ha rechazado tu tarjeta. La solicitud se ha hecho en el modo real, pero nos consta que has usado una tarjeta de prueba.",
  not_permitted: "Titular de tarjeta no inscrito o no permitido",
  pin_try_exceeded: "Se ha sobrepasado el número permitido de intentos para introducir el PIN.",
  test_mode_live_card: "Se ha rechazado tu tarjeta. La solicitud se ha realizado en el modo de prueba, pero la tarjeta utilizada no es válida para este modo. Puedes consultar la lista de tarjetas de prueba válidas en https://stripe.com/docs/testing.",
  transaction_not_allowed: "Tu tarjeta no admite este tipo de compra.",
  generic_decline_link: "Se ha rechazado tu método de pago.",
  generic_decline_non_cards: "Se ha rechazado tu pago.",
}