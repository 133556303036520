<template>
<v-container>
  <p v-if="needBill" class="condtion_list">
  Como cliente deberás tomar las siguientes consideraciones: <br>
  <ul>
    <li> <span v-if="['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'].includes(organization)">Tendrás hasta el final del día de hoy (11:59 pm) para generar tu factura usando el ID que recibirás en el correo de confirmación.  Si no generas tu factura en este periodo, en automático será timbrada a Público General.</span> 
      <span v-else>Una vez concluido el mes, en caso de realizar depósitos a la AMPI, no se podrán hacer devoluciones ni realizar facturas del mes anterior.</span>
    </li>
  <li>
    En caso de requerir factura le recomendamos revisar cuidadosamente sus datos fiscales y cargarlos correctamente para la emisión de su CFDI, así como adjuntar su Constancia de Situación Fiscal Actualizada. <b>(AMPI no se responsabiliza por la emisión de su factura con datos cargados incorrectos)</b>
  </li> 
  <li>En virtud de lo anterior no se podrán realizar cancelaciones posteriores de facturas emitidas por ninguna circunstancia.</li>
  </ul>
  </p>

  <p v-else class="condtion_list">
  Como cliente deberás tomar las siguientes consideraciones: <br>
  <ul>
  <li>
    Al momento de rechazar si quieres emitir la factura, se generará en automático como una venta al público en general, no pudiéndose generar posteriormente por ninguna razón. 
  </li> 
  <li>Una vez cerrado el mes, en caso de realizar depósitos a la AMPI, no se podrán hacer devoluciones ni realizar facturas correspondientes.</li>
  </ul>
  </p>
</v-container>
</template>
<script>


export default {
props:{
  needBill:{
    type:Boolean,
    default:false
  },
  organization: {
    type: String,
    default: ''
  }
},
}

</script>
<style scoped>

.condtion_list{
  font-size: 13px;
}
</style>